import { Injectable, OnInit } from '@angular/core';
import { API, Auth, DataStore, graphqlOperation } from 'aws-amplify';
import { stringify } from 'querystring';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { QueueStatus, Role, Team } from 'src/models';
import { Contact, Deal, DealKanBanColumnSetting, EventType, SMSQueue, UserEvent } from 'src/models';



@Injectable({
  providedIn: 'root'
})
export class EventService implements OnInit {

  /**********************
   * Data within App
   */
  
  private TeamName = new BehaviorSubject('');
  currentTeamName = this.TeamName.asObservable();
  
  private TeamID = new BehaviorSubject('');
  currentTeamID = this.TeamID.asObservable();
  
  private TeamPeers = new BehaviorSubject([]);
  currentTeamPeers = this.TeamPeers.asObservable();

  private team = new BehaviorSubject<Team>(null);
  currentTeam = this.team.asObservable();

  public localTeamID: string;
  public localUserID: string;
  public localTeam:Team;
  public localTeamPeers: string[];
  public localModules: string;
  
  private emailAddress = new BehaviorSubject('');
  currentemailAddress = this.emailAddress.asObservable();

  private profileName = new BehaviorSubject('');
  currentProfileName = this.profileName.asObservable();
  
  private profilePic = new BehaviorSubject('');
  currentprofilePic = this.profilePic.asObservable();

  private dashBoardType = new BehaviorSubject('');
  currentdashBoardType = this.dashBoardType.asObservable();
  
  async ngOnInit(): Promise<void> {
    const user = await Auth.currentAuthenticatedUser({bypassCache: true});
    //console.log(user);

    this.localTeamID=user.attributes["custom:selectedTeamID"];
    this.localUserID = user.attributes.sub;
    //console.log("Event Service  ID set to: ");
    //console.log(this.localUserID);

  }
  
  constructor() { 
    /* localModules character position:
    0) Dashboard type. Default is d
    1) Products type. Default is p
    2) Contacts type. Default is c
    3) Scheduling type. Default is s
    4) Tasks type. Default is t
    5) Deals type. Default is d
    6) Team Settings type. Default is t
    set any of these to "-" to disable the menu item
    */
    this.localModules = "dpcstdt";  
  }

  public async Role(roleName:string) {
    console.log("got role");
    
    try {
      var roleAvailable = await DataStore.query(Role, 
        role => role.team("eq",this.localTeamID).
        and(role => role.user_id("eq",this.localUserID)).
        and(role => role.feature("eq",roleName))); 
        
    } catch (error){
      console.log("Error getting role ", error);
    }
    //this.updateDashboard("dash");
   }


  updateCurrentTeamName(TeamName: string) { this.TeamName.next(TeamName) }

  updateCurrentTeamID(TeamID: string) { 
    this.localTeamID =  TeamID; 
    this.TeamID.next(TeamID); 
    this.setupUserEventSubscription();  
  }

  

  updateCurrentTeam(newTeam: Team) {
    this.localTeamID =  newTeam.id;
    this.TeamID.next(newTeam.id); 
    this.localTeamPeers = newTeam.team_member_ids;
    this.TeamPeers.next(newTeam.team_member_ids);
    this.localTeam = newTeam;
    this.team.next(newTeam);
    
    console.log("Event: Current Team updated");
   }

  updateCurrentTeamPeers(TeamPeers: string[]) { this.TeamPeers.next(TeamPeers) }
  updateEmailAddress(emailAddress: string) { this.emailAddress.next(emailAddress) }
  updateProfileName(profileName: string) { this.profileName.next(profileName) }
  updateProfilePic(profilePic: string) { this.profilePic.next(profilePic) }
  
  updateDashboard(dashBoardType: string) { 
    this.dashBoardType.next(dashBoardType);
    this.localModules =  dashBoardType;
  }

/************************
 * Standard Data
 */

 async enqueSMS(contact: Contact)
 {
   try {
     var cognitoSubID:string = await this.localUserID;
     var teamID:string = await this.localTeamID;
     var retVal;

     retVal = await DataStore.save(
       new SMSQueue ({
         create_id: cognitoSubID,
         team_id: teamID,
         contact_id: contact.id,
         status: QueueStatus.CREATED
       })
     );
     
     //Add the event to the event DB
     this.createSMSQueueEvent(retVal.id);
   } catch (error) {
     console.log("Error scheduling SMS", error);
   }
 }
 
/************************
 * User Event subscription
 */

public user: any;
private UserEventSubscription: Observable<object>;
private UserEventSubscriptionObj: Subscription;

public async setupUserEventSubscription() {
  const user = await Auth.currentAuthenticatedUser({bypassCache: true});
  this.localUserID = user.attributes.sub;
  
  if (this.UserEventSubscriptionObj != null) {
  this.UserEventSubscriptionObj.unsubscribe();
  }

  const StrUserEventCreateSubscription:string  = `subscription {
    onZepysEvent(team_id: "` + this.localTeamID + `") {
      id,
      object_id, 
      event_type
    }}`;

  this.UserEventSubscription = API.graphql(graphqlOperation(StrUserEventCreateSubscription)) as unknown as Observable<object>;

  this.UserEventSubscriptionObj = this.UserEventSubscription.subscribe({
    next: (value: object) => {
      console.log("Event ID is: " + value['value']['data']['onZepysEvent']['id'] + " type is: " + value['value']['data']['onZepysEvent']['event_type'] + " object is: " + value['value']['data']['onZepysEvent']['object_id']);
    },
    error: (error: any) => {
     // console.log(JSON.stringify(error));
    }
  });
  
}

/************************
 * User Events:
  - contact
  - SMSqueue
  - emailqueue
  - meetingqueue
  - task
  - team
  - screen  
 */


  public async createContactEvent(ObjectID: string) {
    this.createUserEvent(EventType.CONTACT, ObjectID);
  }


  public async createSMSQueueEvent(ObjectID: string) {
    this.createUserEvent(EventType.SM_SQUEUE, ObjectID);
  }

  public async createScreenChangeEvent(screen: string) {
    this.createUserEvent(EventType.SCREEN, screen);
  }

  public async createTeamChangeEvent(team_id: string) {
    this.createUserEvent(EventType.TEAM, team_id);
  }

  private async createUserEvent(type: EventType, ObjectID: string) {
    try {
      //First load of browser means the localUserID hasn't loaded yet so make sure there is a localUserID:

      if (this.localUserID){
        await DataStore.save(
          new UserEvent ({
            create_id: this.localUserID,
            team_id: this.localTeamID,
            object_id: ObjectID,
            event_type: type
          })
        );
      }
    } catch (error) {
      console.log("Error storing event", error);
    }
  }

  /************************************
   * Common data functions:
   */
  public toAWSDate(toBeConverted: string){
    console.log("Starting date manip on:" + toBeConverted);
  }


}
