import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, NgZone, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';
import { ButtonComponent} from '@syncfusion/ej2-angular-buttons';
import { DialogComponent, ButtonPropsModel } from '@syncfusion/ej2-angular-popups';
import { Auth, DataStore, Hub, Predicates } from 'aws-amplify';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import {Team} from '../../models/index';
import { ProfileComponent } from '../profile/profile.component';
import { EventService} from '../event.service';
import { SettingsService} from '../settings.service';
import { DealKanBanColumnSetting } from 'src/models';
import { TeamMember } from 'src/models';
import { ReportData } from 'src/models';
import { ReportingService } from '../reporting.service';
import { ReportSettings } from 'src/models';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})

export class TeamComponent implements OnInit {

  constructor(private ngZone: NgZone, public EventService:EventService, public SettingsService:SettingsService, private ReportingService:ReportingService) {
  }

  @ViewChild('CreateTeamDialog') public dlg_CreateTeam: DialogComponent;
  @ViewChild('JoinTeamDialog') public dlg_JoinTeam: DialogComponent;
  
  @ViewChild('teamName') public inp_teamName: ElementRef;
  @ViewChild('teamInviteID') public inp_teamInviteID: ElementRef;
  @ViewChild('teamsDiv') public div_teamCards: ElementRef;
  @ViewChild('element') element;
  

  public dlg_CreateTeamWidth: string = '50%';
  public dlg_CreateTeamHeight: string = '200px';
  public dlg_JoinTeamWidth: string = '50%';
  public dlg_JoinTeamHeight: string = '200px';
  
  public target: string = '.control-section';
  public showCloseIcon: Boolean = false;
  public animationSettings: Object = { effect: 'None' };
  public dlg_CreateTeamHeader: string = 'Team Name';
  public dlg_JoinTeamHeader: string = 'Team Invite';
  public hidden: Boolean = false;
  public position = { X: 'Right', Y: 'Bottom' };
  public dataTeams: Team[];
  public user: any;
  public userattributes: any;
  public teamMembers: TeamMember[];
  
  ngOnInit(): void {
    this.EventService.createScreenChangeEvent("team");
    this.getTeams();

    //Subscription for team change:
    this.EventService.currentTeam.subscribe((team:Team) => {
      console.log("Selected Team has changed event");
      
      console.log(team);

      this.getTeams()
    });
    

    this.ngZone.run(() => {;});
  }

  async onDStoreClear () {
    await DataStore.clear();
  }
  
  public btnClick_CreateTeam = (): void => {
    this.dlg_CreateTeam.show();
  }

  public btnClick_LeaveTeam() {
    alert("Leave a team!! ToDo");
  }
  public btnClick_JoinTeam = (): void => {
    this.dlg_JoinTeam.show();
  }

  public promptDlgBtnClick(theButton:any) {
    this.dlg_CreateTeam.hide();
    
    if(theButton.srcElement.innerText == "Create") {
      this.addTeamObject(this.inp_teamName.nativeElement.value);
    }
  }
  public async onTest() {
    //this.SettingsService.DealPhaseAdd("Fred", "FD", 100, true);
    var cognitoSubID:string = await this.CognitoSubID();
    console.log("Now deleteing all report data");
    await DataStore.delete(ReportData, Predicates.ALL);
    console.log("Now deleteing all report settings");
    await DataStore.delete(ReportSettings, Predicates.ALL);
    
    console.log("Finished deleting");
    //await DataStore.clear();
    
  }

  public async onTeamClick(selectedTeam: Team)
  {
    this.EventService.createTeamChangeEvent(selectedTeam.id);

    this.user = await Auth.currentAuthenticatedUser({bypassCache: true});
    
    Auth.updateUserAttributes(this.user, {
      'custom:selectedTeam': selectedTeam.description,
      'custom:selectedTeamID': selectedTeam.id,
    });
    await this.ReportingService.changeTeam();
    this.EventService.updateCurrentTeam(selectedTeam);
    console.log("Changed Selected Team:" + selectedTeam.id);
  }

  public onBlur = (): void => {
    this.inp_teamName.nativeElement.parentElement.classList.remove('e-input-focus');
  }

  public ondlgCreateTeamFocus = (): void => {
  this.inp_teamName.nativeElement.parentElement.classList.add('e-input-focus');
  }

  public ondlgJoinTeamFocus = (): void => {
    this.inp_teamInviteID.nativeElement.parentElement.classList.add('e-input-focus');
    }

  public promptDlgCreateButtons: ButtonPropsModel[] = [{ click: this.promptDlgBtnClick.bind(this), buttonModel: { content: 'Create', isPrimary: true } }, { click: this.promptDlgBtnClick.bind(this), buttonModel: { content: 'Cancel' } }];
  public promptDlgJoinButtons: ButtonPropsModel[] = [{ click: this.promptDlgBtnClick.bind(this), buttonModel: { content: 'Join', isPrimary: true } }, { click: this.promptDlgBtnClick.bind(this), buttonModel: { content: 'Cancel' } }];
  
  async CognitoSubID():Promise<string> {
    const user = await Auth.currentAuthenticatedUser({bypassCache: true});
    return(user.attributes.sub);
  }

  async btnClick_SetTeamDealColumns() {
    //Delete existing columns:
    this.SettingsService.DealPhaseRemoveAll();

    //Add the default columns:
    this.SettingsService.DealPhaseAdd("Lead","lead", 0, true);
    this.SettingsService.DealPhaseAdd("Marketing QL","mql", 1, true);
    this.SettingsService.DealPhaseAdd("Sales QL","sql", 2, true);
    this.SettingsService.DealPhaseAdd("Opportunity","opp", 3, true);
    
    this.SettingsService.DealPhaseAssignAllDeals("lead");
    this.element.show( { title: 'Success!', content: 'Deal phases reset to default, all deals moved to Lead phase', cssClass: 'e-toast-success'},);
  }
  
  async addTeamObject(description:string)
  {
    var cognitoSubID:string = await this.CognitoSubID();
    var JSONMemIDs:string[] = [cognitoSubID];

    try {
      const NewTeam = await DataStore.save(
        new Team ({
          description: description,
          create_id: cognitoSubID,
          team_member_ids: JSONMemIDs,
          team_member_count: 1, 
          activity: "",
          mrr: 0.0
        })
      );

      await DataStore.save(
        new TeamMember({
          team_id: NewTeam.id, 
          team_member_id: cognitoSubID,
          first_name: this.user.attributes["given_name"],
          last_name: this.user.attributes["family_name"],
          role: "Founder"
        })
      )
     
      this.getTeams();
      this.ngZone.run(() => {;});
      this.element.show( { title: 'Success!', content: 'Team "' + description + '" formed', cssClass: 'e-toast-success'},);
    } catch (error) {
      console.log("Error adding team", error);
    }
  }

  async getTeams() {
    console.log(this.CognitoSubID());
    this.dataTeams = await DataStore.query(Team); 
    this.getTeamMembers();
    
  }

  async getTeamMembers() {
    this.teamMembers = await DataStore.query(TeamMember, (c) => c.team_id("eq", this.EventService.localTeamID));
  }

  onCreateToast(event: any) { ; }
}