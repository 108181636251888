<br>
<!-- Render the create team Dialog -->
<div class="control-section" style="padding:10px;position:relative;">
<ejs-dialog #CreateTeamDialog 
[buttons]='promptDlgCreateButtons' 
[visible]='hidden' 
[header]='dlg_CreateTeamHeader' 
[animationSettings]='animationSettings' 
[showCloseIcon]='showCloseIcon' 
[width]='dlg_CreateTeamWidth' 
[minHeight]="dlg_CreateTeamHeight"
>
  <!-- Prompt Dialog content  -->
  <ng-template #content>
      <span class="e-input-group">
        <input #teamName id="teamName" (focus)='ondlgCreateTeamFocus()' (blur)='onBlur()' name="teamName" class="e-input" style="width:100%; height:100%;"/>
      </span>
  </ng-template>
</ejs-dialog>


<!-- Render the join team Dialog -->
<div class="control-section" style="padding:10px;position:relative;">
  <ejs-dialog #JoinTeamDialog 
  [buttons]='promptDlgJoinButtons' 
  [visible]='hidden' 
  [header]='dlg_JoinTeamHeader' 
  [animationSettings]='animationSettings' 
  [showCloseIcon]='showCloseIcon' 
  [width]='dlg_JoinTeamWidth' 
  [minHeight]="dlg_JoinTeamHeight"
  >
    <!-- Prompt Dialog content  -->
    <ng-template #content>
        <span class="e-input-group">
          <input #teamInviteID id="teamInvite" (focus)='ondlgJoinTeamFocus()' (blur)='onBlur()' name="teamName" class="e-input" style="width:100%; height:100%;"/>
        </span>
    </ng-template>
  </ejs-dialog>

  
<!--Teams-->
<div style="margin: 50px;display: flex;flex-direction: row;justify-content: left;">
  <div #teamsDiv *ngFor="let team of this.dataTeams; index as i; first as isFirst" tabindex="0" class="e-card e-card-horizontal" style="width:400px; margin: 5px;" >
    <div  class="e-card-stacked">
      <div class="e-card-header">
          <div class="e-card-header-caption">
              <div class="e-card-header-title"><b>{{ team.description }}</b></div>
          </div>
      </div>
      <div class="e-card-content">
        <table>
          <tr><td>Members:</td><td>{{ team.team_member_count }}</td></tr>
          <tr><td>Activity:</td><td>{{ team.activity }}</td></tr>
          <tr><td>Monthly Recurring Revenue:</td><td>{{ team.mrr }}</td></tr>
        </table><br>
        <div *ngIf="team.id != this.EventService.localTeamID; else TeamSelectedBlock"><button  ejs-button class='dlgbtn' #btn_SelectTeam (click)="onTeamClick(team)">Select team</button></div>
        <ng-template #TeamSelectedBlock><div><b>Selected team</b></div></ng-template>
        <button ejs-button class='dlgbtn' #btn_LeaveTeam (click)="btnClick_LeaveTeam()">Leave team</button><br>
      </div>
  </div>
  </div>
</div>

<!--Members-->
  <div style="margin: 50px;display: flex;flex-direction: row;justify-content: left;">
  <div #teamsDiv *ngFor="let member of this.teamMembers index as i; first as isFirst" tabindex="0" class="e-card e-card-horizontal" style="width:400px; margin: 5px;" >
    <div  class="e-card-stacked">
      <div class="e-card-header">
          <div class="e-card-header-caption">
              <div class="e-card-header-title"><b>{{ member.honourific }} {{ member.first_name }} {{ member.last_name }}</b></div>
          </div>
      </div>
      <div class="e-card-content">
        <table>
          <tr><td>First name: </td><td>{{ member.first_name }}</td></tr>
          <tr><td>Last name: </td><td>{{ member.last_name }}</td></tr>
          <tr><td>Role: </td><td>{{ member.role }}</td></tr>
        </table>
      </div>
  </div>
  </div>
</div>


<!--Buttons-->
<button ejs-button class='dlgbtn' #btn_CreateTeam (click)="btnClick_CreateTeam()">Form a new team</button><br>
<button ejs-button class='dlgbtn' #btn_JoinTeam (click)="btnClick_JoinTeam()">Join a team</button><br>
<button ejs-button class='dlgbtn' #btn_SetTeamDealColumns (click)="btnClick_SetTeamDealColumns()">Reset Selected Team's Deal phases to Lead, MQL, SQL, Opportunity</button><br>
<button ejs-button class='dlgbtn' #btn_ResetDStore (click)="onDStoreClear()">Clear and reload cache</button><br><br>
<button ejs-button class='dlgbtn' #btn_test (click)="onTest()">truncate report tables for this team</button>
<ejs-toast #element (created)="onCreateToast($event)" [position] = 'position' ></ejs-toast>
</div>


